import Image from "next/image";
import Button from "@/components/ui/button";
import SplitImagesWithTitle, {
  SplitItems,
} from "@/components/ui/SplitImagesWithTitle";
import Link from "@/components/ui/Link";

interface ResolutionOfBruisingProps {
  data?: any;
}

const items: SplitItems = [
  {
    title: "6-weeks after facelift & neck lift surgery",
    imageSrc: "/images/content_split_left_image_white_img_3.jpg",
  },
  {
    title: "6-weeks after facelift & neck lift surgery",
    imageSrc: "/images/content_split_left_image_white_img_3.jpg",
  },
];

export default function ResolutionOfBruising({
  data,
}: ResolutionOfBruisingProps) {
  return (
    <section id="resolution-of-bruising" className="py-14 bg-white">
      <div className="max-w-[1080px] mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8 mb-12">
          <div className="lg:col-span-2">
            <div className="prose max-w-none text-[#000]">
              <p className="font-aileron mb-4">
                The next set of photos at 6-weeks after surgery shows resolution
                of all of the early bruising and 95% of swelling. The patient
                has had a dramatic rejuvenation in her face areas. Multiple
                people commented on how refreshed she looked although they were
                not able to tell she had undergone surgery as the result is
                completely natural.
              </p>
              <p className="font-aileron mb-4">
                The scars are show at 6-weeks after surgery, have faded further.
                Although the final result from scars is as long as 18-months
                after surgery, by hiding scars in the natural creases Dr. De
                Silva finds that scars can be made discrete far earlier,
                unfortunately scars that have been placed in more visible areas
                are very difficult to treat. The patient was very happy with the
                results of her surgery, she had been extremely nervous of
                undergoing surgery and had waited more than 10years before she
                had made the decision to undergo surgery with Dr. De Silva.
              </p>
              <p className="font-aileron">
                Request a consultation at our office in London to take the next
                step and learn more about your cosmetic options.
              </p>
            </div>
          </div>
          <div className="lg:col-span-1">
            <Image
              src="/images/content_split_left_image_white_img_3.jpg"
              alt="6 weeks after surgery"
              width={1158}
              height={880}
              className="rounded-lg w-full h-auto"
              quality={95}
              loading={"lazy"}
            />
          </div>
        </div>
        <SplitImagesWithTitle items={items} />
        <div className="flex justify-center">
          <Button variant="outline" asChild className="mt-12">
            <Link href="/book-a-consultation">Book a consultation</Link>
          </Button>
        </div>
      </div>
    </section>
  );
}

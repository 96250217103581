"use client";

import { useState, useEffect } from "react";
import { sanitizeHTML } from "@/lib/utils";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import Autoplay from "embla-carousel-autoplay";

interface SecCombineData {
	heading?: {
		text?: string
		title?: string
	}
	text?: {
		text?: string
	}
	content_collection?: {
		id: string
		site_content_collections_id: {
			collection: {
				item: {
					title: string
					text: string
				}
			}[]
		}
	}[]
}

export const fields = [
	'heading.text',
	'heading.title',
	'text.text',
	'content_collection.id',
	'content_collection.site_content_collections_id.collection.item:site_content.title',
	'content_collection.site_content_collections_id.collection.item:site_content.text',
] as const

interface SecCombineProps {
  data?: SecCombineData;
}

export default function SecCombine({ data }: SecCombineProps) {
  const [isMobile, setIsMobile] = useState(false);
  const contentItems = data?.content_collection || [];
  const showAsGrid = contentItems.length <= 3;

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    
    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  return (
		<section className='sec-combine bg-white py-20'>
			<div className='container'>
				<div className='flex flex-col items-center'>
					<h2
						className='font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark mb-10 text-left'
						dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || '')}
					/>
					<div
						className='font-aileron text-base font-light leading-[27px] tracking-tight text-[#212529] md:col-span-7 text-center w-full md:w-1/2 mx-auto mb-10'
						dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
					/>

					{showAsGrid && !isMobile ? (
						<div className='w-full pb-16 grid grid-cols-1 md:grid-cols-3 gap-8 max-w-[1200px] mx-auto'>
							{contentItems.map((item) => (
								<div key={item.id} className='flex flex-col gap-4'>
									<h3
										className='text-3xl font-light font-freightBig leading-[42px] tracking-wider text-black'
										dangerouslySetInnerHTML={sanitizeHTML(
											item?.site_content_collections_id?.collection?.[0]
												?.item?.title || ''
										)}
									/>
									<div
										className='text-base font-light font-aileron leading-relaxed tracking-wide prose'
										dangerouslySetInnerHTML={sanitizeHTML(
											item?.site_content_collections_id?.collection?.[1]
												?.item?.text || ''
										)}
									/>
								</div>
							))}
						</div>
					) : (
						<div className='w-full pb-16'>
							<Carousel
								opts={{
									align: 'start',
									loop: true,
								}}
								plugins={[
									Autoplay({
										delay: 5000,
									}),
								]}
								className='w-full max-w-[1200px] mx-auto'
							>
								<CarouselContent className='-ml-2 md:-ml-4'>
									{data?.content_collection?.map((slide, index) => (
										<CarouselItem
											key={slide.id}
											className='pl-2 md:pl-4 basis-full md:basis-1/4'
										>
											<div className='flex flex-col gap-4'>
												<h3
													className='text-3xl font-light font-freightBig leading-[42px] tracking-wider text-black'
													dangerouslySetInnerHTML={sanitizeHTML(
														slide?.site_content_collections_id?.collection?.[0]
															?.item?.title || ''
													)}
												/>
												<div
													className='text-base font-light font-aileron leading-relaxed tracking-wide prose'
													dangerouslySetInnerHTML={sanitizeHTML(
														slide?.site_content_collections_id?.collection?.[1]
															?.item?.text || ''
													)}
												/>
											</div>
										</CarouselItem>
									))}
								</CarouselContent>
								<div className={`md:flex absolute right-8 -bottom-8 gap-2 w-[70px] ${showAsGrid ? 'md:hidden' : ''}`}>
									<CarouselPrevious className='w-10 h-10 border border-black rounded-full flex items-center justify-center hover:border-black hover:text-white hover:bg-black transition-colors bg-transparent text-black' />
									<CarouselNext className='w-10 h-10 border border-black rounded-full flex items-center justify-center hover:border-black hover:text-white hover:bg-black transition-colors bg-transparent text-black' />
								</div>
							</Carousel>
						</div>
					)}
				</div>
			</div>
		</section>
	)
}
import { useState } from 'react';
import { sanitizeHTML } from '@/lib/utils';

interface SecExpectData {
  heading?: {
    text?: string;
    title?: string;
  };
	text?: {
		text?: string;
	};
	button?: {
		label?: string;
		href?: string;
	};
  content_collection?: {
    id: string;
    site_content_collections_id: {
      collection: {
        item: {
          title: string;
          text: string;
        };
      }[];
    };
  }[];
}

export const fields = [
  'heading.text',
  'heading.title',
	'text.text',
  'content_collection.id',
  'content_collection.site_content_collections_id.collection.item:site_content.title',
  'content_collection.site_content_collections_id.collection.item:site_content.text',
	'button.label',
	'button.href',
] as const;

interface SecExpectProps {
  data?: SecExpectData;
}

export default function SecExpect({ data }: SecExpectProps) {
  const [closedSections, setClosedSections] = useState<number[]>([]);

  const toggleSection = (index: number) => {
    setClosedSections(prev => 
      prev.includes(index) 
        ? prev.filter(i => i !== index) 
        : [...prev, index]
    );
  };

  return (
		<section className='sec-expect w-full py-20 bg-[#F4F4F4]'>
			<div className='container'>
				<div className='bg-white p-8 md:p-16'>
					<div className='mb-12 grid grid-cols-1 md:grid-cols-12 gap-1 md:gap-8'>
						<h2
							className="font-freightBig text-4xl leading-tight md:leading-normal md:text-5xl text-secondary-dark mb-8 text-left md:col-span-5"
							dangerouslySetInnerHTML={sanitizeHTML(data?.heading?.title || '')}
						/>
						<div
							className='font-aileron text-base font-light leading-[27px] tracking-tight text-[#212529] md:col-span-7'
							dangerouslySetInnerHTML={sanitizeHTML(data?.text?.text || '')}
						/>
					</div>

					<div className='flex flex-col'>
						{data?.content_collection?.map((section, index) => (
							<div key={section.id} className='border-b border-black py-4'>
								<div className='flex flex-col md:flex-row md:items-start md:justify-between md:gap-8'>
									<button
										className='flex w-full md:w-[300px] items-center justify-between rounded-[100px] bg-black px-6 py-3 mb-4 md:mb-0'
										onClick={() => toggleSection(index)}
									>
										<div className='flex items-center gap-2'>
											<span className='font-serenity text-base font-semibold text-white'>
												{index + 1}.
											</span>
											<span
												className='font-serenity text-base font-semibold capitalize tracking-wider text-white text-left'
												dangerouslySetInnerHTML={sanitizeHTML(
													section?.site_content_collections_id?.collection?.[0]
														?.item?.title || ''
												)}
											/>
										</div>
										<span className='font-serenity text-base font-semibold text-white'>
											{closedSections.includes(index) ? '+' : '−'}
										</span>
									</button>

									{!closedSections.includes(index) && (
										<div
											className='flex-1 font-aileron text-base font-light leading-[27px] tracking-tight text-[#212529] md:columns-2 gap-8'
											dangerouslySetInnerHTML={sanitizeHTML(
												section?.site_content_collections_id?.collection?.[1]
													?.item?.text || ''
											)}
										/>
									)}
								</div>
							</div>
						))}
					</div>

					<div className='mt-12 flex justify-center'>
						<a
							href={data?.button?.href || ''}
							className='rounded-[60px] border border-black px-[35px] py-[25px] font-serenity text-base font-semibold capitalize leading-tight tracking-wider text-[#332f2c]'
						>
							{data?.button?.label || ''}
						</a>
					</div>
				</div>
			</div>
		</section>
	)
}